import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/Layout.vue'),
	redirect: '/index',
	meta: {
	  title: '主页'
	},
	children: [
		{
		  path: '/index',
		  component: () => import('../views/index/index.vue'),
		  name: 'Index',
		  meta: {
			title: '工作台'
		  },
		  children: null
		},
		{
		  path: '/setting',
		  component: () => import('../views/system/setting/index.vue'),
		  name: 'Setting',
		  meta: {
			title: '系统设置'
		  },
		  children: null
		},
		{
		  path: '/admin_role',
		  component: () => import('../views/system/admin_role/index.vue'),
		  name: 'AdminRole',
		  meta: {
			title: '角色管理'
		  },
		  children: null
		},
		{
		  path: '/admin',
		  component: () => import('../views/system/admin/index.vue'),
		  name: 'Admin',
		  meta: {
			title: '管理员列表'
		  },
		  children: null
		},
		{
		  path: '/operation',
		  component: () => import('../views/system/operation/index.vue'),
		  name: 'Operation',
		  meta: {
			title: '权限管理'
		  },
		  children: null
		},
		{
		  path: '/operation_log',
		  component: () => import('../views/system/operation_log/index.vue'),
		  name: 'OperationLog',
		  meta: {
			title: '系统日志'
		  },
		  children: null
		},
		{
		  path: '/article_type',
		  component: () => import('../views/operate/article_type/index.vue'),
		  name: 'ArticleType',
		  meta: {
			title: '文章类型'
		  },
		  children: null
		},
		{
		  path: '/article',
		  component: () => import('../views/operate/article/index.vue'),
		  name: 'Article',
		  meta: {
			title: '文章管理'
		  },
		  children: null
		},
		{
		  path: '/article_edit',
		  component: () => import('../views/operate/article/edit.vue'),
		  name: 'ArticleEdit',
		  meta: {
			title: '编辑文章'
		  },
		  children: null
		},
		{
		  path: '/banners',
		  component: () => import('../views/operate/banners/index.vue'),
		  name: 'Banners',
		  meta: {
			title: '广告管理'
		  },
		  children: null
		},
		{
		  path: '/menus',
		  component: () => import('../views/operate/menus/index.vue'),
		  name: 'Menus',
		  meta: {
			title: '菜单管理'
		  },
		  children: null
		},
		{
		  path: '/user_grade',
		  component: () => import('../views/user/user_grade/index.vue'),
		  name: 'UserGrade',
		  meta: {
			title: '用户等级'
		  },
		  children: null
		},
		{
		  path: '/user_role',
		  component: () => import('../views/user/user_role/index.vue'),
		  name: 'UserRole',
		  meta: {
			title: '用户身份'
		  },
		  children: null
		},
		{
		  path: '/user',
		  component: () => import('../views/user/user/index.vue'),
		  name: 'User',
		  meta: {
			title: '用户列表',
			keepAlive: true 
		  },
		  children: null
		},
		{
		  path: '/user_edit',
		  component: () => import('../views/user/user/edit.vue'),
		  name: 'UserEdit',
		  meta: {
			title: '编辑用户'
		  },
		  children: null
		},
		{
		  path: '/user_child',
		  component: () => import('../views/user/user/child.vue'),
		  name: 'UserChild',
		  meta: {
			title: '子账号'
		  },
		  children: null
		},
		{
		  path: '/user_detail',
		  component: () => import('../views/user/user/detail.vue'),
		  name: 'UserDetail',
		  meta: {
			title: '会员详情'
		  },
		  children: null
		},
		{
		  path: '/user_jfjl',
		  component: () => import('../views/user/user/user_jfjl.vue'),
		  name: 'UserDetail',
		  meta: {
			title: '缴费记录'
		  },
		  children: null
		},
		{
		  path: '/invoice',
		  component: () => import('../views/user/invoice/index.vue'),
		  name: 'Invoice',
		  meta: {
			title: '开票申请'
		  },
		  children: null
		},
		{
		  path: '/notice',
		  component: () => import('../views/operate/notice/index.vue'),
		  name: 'User',
		  meta: {
			title: '消息管理'
		  },
		  children: null
		},
		{
		  path: '/xhxc',
		  component: () => import('../views/content/xhxc/index.vue'),
		  name: 'Xhxc',
		  meta: {
			title: '协会相册'
		  },
		  children: null
		},
		{
		  path: '/xhxc_edit',
		  component: () => import('../views/content/xhxc/edit.vue'),
		  name: 'XhxcEdit',
		  meta: {
			title: '协会相册'
		  },
		  children: null
		},
		{
		  path: '/xhsp',
		  component: () => import('../views/content/xhsp/index.vue'),
		  name: 'Xhsp',
		  meta: {
			title: '协会视频'
		  },
		  children: null
		},
		{
		  path: '/xhsp_edit',
		  component: () => import('../views/content/xhsp/edit.vue'),
		  name: 'XhspEdit',
		  meta: {
			title: '协会视频'
		  },
		  children: null
		},
		{
		  path: '/djzx',
		  component: () => import('../views/content/djzx/index.vue'),
		  name: 'Djzx',
		  meta: {
			title: '党建中心'
		  },
		  children: null
		},
		{
		  path: '/djzx_edit',
		  component: () => import('../views/content/djzx/edit.vue'),
		  name: 'DjzxEdit',
		  meta: {
			title: '党建中心'
		  },
		  children: null
		},
		{
		  path: '/dzhk',
		  component: () => import('../views/content/dzhk/index.vue'),
		  name: 'Dzhk',
		  meta: {
			title: '电子会刊'
		  },
		  children: null
		},
		{
		  path: '/dzhk_edit',
		  component: () => import('../views/content/dzhk/edit.vue'),
		  name: 'DzhkEdit',
		  meta: {
			title: '电子会刊'
		  },
		  children: null
		},
		{
		  path: '/xhdt',
		  component: () => import('../views/content/xhdt/index.vue'),
		  name: 'Xhdt',
		  meta: {
			title: '协会动态'
		  },
		  children: null
		},
		{
		  path: '/xhdt_edit',
		  component: () => import('../views/content/xhdt/edit.vue'),
		  name: 'XhdtEdit',
		  meta: {
			title: '协会动态'
		  },
		  children: null
		},
		{
		  path: '/activity',
		  component: () => import('../views/activity/activity/index.vue'),
		  name: 'Activity',
		  meta: {
			title: '活动管理'
		  },
		  children: null
		},
		{
		  path: '/activity_edit',
		  component: () => import('../views/activity/activity/edit.vue'),
		  name: 'ActivityEdit',
		  meta: {
			title: '活动管理'
		  },
		  children: null
		},
		{
		  path: '/activity_list',
		  component: () => import('../views/activity/activity/list.vue'),
		  name: 'ActivityList',
		  meta: {
			title: '活动报名'
		  },
		  children: null
		},
		{
		  path: '/wj',
		  component: () => import('../views/activity/wj/index.vue'),
		  name: 'Wj',
		  meta: {
			title: '问卷管理'
		  },
		  children: null
		},
		{
		  path: '/wj_edit',
		  component: () => import('../views/activity/wj/edit.vue'),
		  name: 'WjEdit',
		  meta: {
			title: '问卷编辑'
		  },
		  children: null
		},
		{
		  path: '/wj_list',
		  component: () => import('../views/activity/wj/list.vue'),
		  name: 'WjList',
		  meta: {
			title: '问卷列表'
		  },
		  children: null
		},
		{
		  path: '/ls',
		  component: () => import('../views/user/ls/index.vue'),
		  name: 'Ls',
		  meta: {
			title: '问卷管理'
		  },
		  children: null
		},
		{
		  path: '/template_params',
		  component: () => import('../views/activity/template_params/index.vue'),
		  name: 'TemplateParams',
		  meta: {
			title: '模版参数'
		  },
		  children: null
		},
		{
		  path: '/template',
		  component: () => import('../views/activity/template/index.vue'),
		  name: 'TemplateParams',
		  meta: {
			title: '模版管理'
		  },
		  children: null
		},
		{
		  path: '/jfjl',
		  component: () => import('../views/user/jfjl/index.vue'),
		  name: 'Jfjl',
		  meta: {
			title: '缴费记录'
		  },
		  children: null
		},
		{
		  path: '/user_delete',
		  component: () => import('../views/user/delete/index.vue'),
		  name: 'Userdelete',
		  meta: {
			title: '删除会员'
		  },
		  children: null
		},
		{
		  path: '/user_log',
		  component: () => import('../views/user/user_log/index.vue'),
		  name: 'UserLog',
		  meta: {
			title: '变更记录'
		  },
		  children: null
		},
		{
		  path: '/djhy',
		  component: () => import('../views/user/djhy/index.vue'),
		  name: 'Djhy',
		  meta: {
			title: '冻结会员'
		  },
		  children: null
		},
		{
		  path: '/apply',
		  component: () => import('../views/user/apply/index.vue'),
		  name: 'Apply',
		  meta: {
			title: '会员申请'
		  },
		  children: null
		},
		{
		  path: '/feedback',
		  component: () => import('../views/operate/feedback/index.vue'),
		  name: 'Feedback',
		  meta: {
			title: '会员反馈'
		  },
		  children: null
		},
		{
		  path: '/message',
		  component: () => import('../views/message/index.vue'),
		  name: 'Content',
		  meta: {
			title: '聊天内容'
		  },
		  children: null
		},
		{
		  path: '/user_activity_list',
		  component: () => import('../views/user/user_activity_list/index.vue'),
		  name: 'UserActivityList',
		  meta: {
			title: '活动记录'
		  },
		  children: null
		},
		{
		  path: '/ckqx',
		  component: () => import('../views/system/ckqx/index.vue'),
		  name: 'Ckqx',
		  meta: {
			title: '查看权限'
		  },
		  children: null
		},
		{
		  path: '/hytz',
		  component: () => import('../views/user/hytz/index.vue'),
		  name: 'Hytz',
		  meta: {
			title: '会议通知'
		  },
		  children: null
		},
		{
		  path: '/import',
		  component: () => import('../views/system/import/index.vue'),
		  name: 'Import',
		  meta: {
			title: '导入相关'
		  },
		  children: null
		},
		{
		  path: '/apply_ls',
		  component: () => import('../views/user/apply_ls/index.vue'),
		  name: 'ApplyLs',
		  meta: {
			title: '理事申请'
		  },
		  children: null
		},
	],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// 获取token
	let token = Vue.prototype.VueCookies.get("token")
	if(token){ // 已登录
	    // 防止重复登录
		if(to.path === '/login'){
			// Vue.prototype.$message.error('请不要重复登录');
			return next({path:from.path ? from.path : '/'})
		}
		//其他验证
		// 验证权限
		// let rules = window.sessionStorage.getItem('rules')
		// rules = rules ? JSON.parse(rules) : []

		// let index = rules.findIndex(item=>{
		// 	return  item.rule_id > 0 && item.desc === to.name
		// })
		// if(index === -1){
		// 	Vue.prototype.$message.error('你没有权限')
		// 	return next({name:from.name ? from.name : '/'})
		// }
		
	    next();
	}else{
		// 跳过登录页验证
		if(to.path === '/login'){
			return next();
		}
		// 未登录
		// Vue.prototype.$message.error('请先登录');
		next({ path:'/login' })
	}
})

export default router
