import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import './icons'

import VueCookies from 'vue-cookies';
import * as echarts from 'echarts'
Vue.use(VueCookies);
Vue.use(echarts);
Vue.prototype.VueCookies = VueCookies;
Vue.prototype.$echarts = echarts;

import iconPicker from 'e-icon-picker';
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库
Vue.use(iconPicker, { FontAwesome: false, ElementUI: true, eIcon: false, eIconSymbol: false }); 

import './assets/css/icons/icomoon/styles.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { on } from 'element-ui/src/utils/dom'
Vue.use(ElementUI);

import './assets/css/element-variables.scss'; 
import './assets/css/style.scss'; 

import RightToolbar from '@/components/RightToolbar'
import Pagination from '@/components/Pagination'
Vue.component('RightToolbar', RightToolbar)
Vue.component('Pagination', Pagination)

import { resetForm, handleDateRange, getTreeCheckedKeys, setTreeNodeCheck } from '@/utils/common'
Vue.prototype.resetForm = resetForm
Vue.prototype.handleDateRange = handleDateRange
Vue.prototype.getTreeCheckedKeys = getTreeCheckedKeys
Vue.prototype.setTreeNodeCheck = setTreeNodeCheck

//拖拽
import VueDND from 'awe-dnd'
Vue.use(VueDND)

//vue-jsonp
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.prototype.$bus = new Vue();

//loading
Vue.prototype.openLoading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: '加载中',                         // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.8)',       // 背景颜色
    target: '.page',       // **需要遮罩的区域，这里写要添加loading的选择器**
    fullscreen: false,
    customClass: 'loadingclass'             // **遮罩层新增类名，如果需要修改loading的样式**
  })
  // setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
  //   loading.close();                        // 关闭遮罩层
  // },10000)
  return loading;
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
