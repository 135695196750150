export default {
	state:{
       shop_name:'',
	   company_name:'',
	   shop_address:'',
	   shop_desc:'',
	   shop_beian:'',
	   kf_mobile:'',
	   search_keywords:'',
	   shop_logo:'',
	   full_name:'',
	   xh_address:'',
	   zxrx:'',
	   zhb_phone:'',
	   hyb_phone:'',
	   xzb_phone:'',
	   pxzx_phone:'',
	   xh_fax:'',
	   xh_email:'',
	   xh_longitude:'',
	   xh_latitude:'',
	   //上传设置
	   image_storage_type:'',
	   image_storage_params:{
		   domain:'',
		   //阿里云
		   accessKeyId:'',
		   accessKeySecret:'',
		   endpoint:'',
		   oss_bucket:'',
		   //腾讯云Cos
		   secretId:'',
		   secretKey:'',
		   region:'',
		   bucket:'',
		   //七牛云Cos
		   accessKey:'',
		   secretKey:'',
		   // region:'',
		   // bucket:'',
	   },
	   //阿里云短信
	   sms_type:'Alisms',
	   AccessKeyId:'',
	   AccessKeySecret:'',
	   aliyunsign:'',
	   TemplateCode:'',
	   //腾讯云短信
	   secretId:'',
	   secretKey:'',
	   SmsSdkAppId:'',
	   signName:'',
	   TemplateId:'',
	},
	getters:{
		getData: state => state
	},
	mutations:{
       //修改state
       editState(state,{key,val}){
       	 state[key] = val
       },
	   editParamsState(state,{name,key,val}){
	   	 state[name][key] = val
 	   },
	   initSetting(state,data){
		   data.map(v=>{
			   var reg = /{/g;
			   var res = v.value.match(reg);
			   if(res != null){
				   let obj = JSON.parse(v.value)
				   for(var i in obj) {
				   		state[v.skey][i] = obj[i]
				   }
			   }else{
				   state[v.skey] = v.value
			   }
		   })
	   }
	},
	actions:{
		
	}
}